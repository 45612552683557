<template>
  <div class="content-op-dashboard">
    <div class="content-dashboard-info">
        <!-- #region MXN -->
        <div class="content-dashboard-info-MXN">
          <div class="content-dashboard-oc">
            <div class="content-paid">
              <div class="content-text">
                <span class="info-text">
                  <!-- Monto acumulado por OC pagados -->
                  Ingresos
                </span>
              </div>
              <div class="content-icon-number">
                <div class="content-btn">
                  <v-btn depressed class="btn-icon-currency">
                    <span class="material-icons icon-currency-dash">
                      attach_money
                    </span>
                  </v-btn>
                </div>
                <div class="content-number">
                  <span class="number-text">
                    $ {{ formatMoneyGlobal(incomes) }} MXN
                  </span>
                </div>
              </div>
            </div>
          </div>
          <v-spacer></v-spacer>
          <div class="content-dashboard-so">
            <div class="content-paid">
              <div class="content-text">
                <span class="info-text">
                  <!-- Monto acumulado por PV pagados -->
                  Egresos
                </span>
              </div>
              <div class="content-icon-number">
                <div class="content-btn">
                  <v-btn
                    style="background-color: red !important"
                    depressed
                    class="btn-icon-currency"
                  >
                    <span class="material-icons icon-currency-dash">
                      attach_money
                    </span>
                  </v-btn>
                </div>
                <div class="content-number">
                  <span class="number-text">
                    $ {{ formatMoneyGlobal(expenses) }} MXN
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- #endregion MXN -->

        <!-- #region USD -->
        <div class="content-dashboard-info-USD">
          <div class="content-dashboard-oc">
            <div class="content-paid">
              <div class="content-text">
                <span class="info-text">
                  <!-- Monto acumulado por OC pagados -->
                  Ingresos
                </span>
              </div>
              <div class="content-icon-number">
                <div class="content-btn">
                  <v-btn depressed class="btn-icon-currency">
                    <span class="material-icons icon-currency-dash">
                      attach_money
                    </span>
                  </v-btn>
                </div>
                <div class="content-number">
                  <span class="number-text">
                    $ {{ formatMoneyGlobal(incomesUSD) }} USD
                  </span>
                </div>
              </div>
            </div>
          </div>
          <v-spacer></v-spacer>
          <div class="content-dashboard-so">
            <div class="content-paid">
              <div class="content-text">
                <span class="info-text">
                  <!-- Monto acumulado por PV pagados -->
                  Egresos
                </span>
              </div>
              <div class="content-icon-number">
                <div class="content-btn">
                  <v-btn
                    style="background-color: red !important"
                    depressed
                    class="btn-icon-currency"
                  >
                    <span class="material-icons icon-currency-dash">
                      attach_money
                    </span>
                  </v-btn>
                </div>
                <div class="content-number">
                  <span class="number-text">
                    $ {{ formatMoneyGlobal(expensesUSD) }} USD
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- #endregion USD -->

    </div>
  </div>
</template>

<script>
export default {
  props: {
    incomes: Number,
    expenses: Number,
    incomesUSD: Number,
    expensesUSD: Number,
  },
};
</script>

<style scoped>
.content-filter-range-date {
  display: flex;
  justify-content: flex-end;
}

.content-dashboard-info {
  display: block;
  margin-bottom: 20px;
}
.content-dashboard-info-MXN {
  display: flex;
  margin-bottom: 20px;
}
.content-dashboard-info-USD {
  display: flex;
  margin-bottom: 20px;
}

.content-dashboard-oc,
.content-dashboard-so {
  width: 100%;
  height: 85px;
  margin-right: 10px;
  padding: 10px 20px 10px 20px;
  background-color: var(--primary-color-background-table) !important;
  border: 1px solid var(--primary-color-border-input);
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-align: center;
}

.content-dashboard-oc-OPPending,
.content-dashboard-oc-OPPayable,
.content-dashboard-so-OPPending,
.content-dashboard-so-OPPayable {
  width: 100%;
  height: 85px;
  margin-right: 10px;
  padding: 10px 20px 10px 20px;
  background-color: var(--primary-color-background-table) !important;
  border: 1px solid var(--primary-color-border-input);
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-align-last: center !important;
}

.content-finished {
  margin-right: 0.5%;
  width: 100%;
  min-width: 10%;
}

.content-paid {
  margin-left: 0.5%;
  width: 100%;
  min-width: 10%;
}

.content-icon-number {
  display: flex;
  align-items: center;
  place-content: center;
}

.content-icon-number-OPPending,
.content-icon-number-OPPayable {
  display: flex;
  align-items: center;
  place-content: start;
  justify-content: center !important;
}

.content-number {
  margin-left: 10px;
}

.btn-icon-pound {
  background-color: var(--primary-color-background-icon-status-blue) !important;
  width: 30px !important;
  max-width: 30px !important;
  min-width: 30px !important;
  height: 30px !important;
  max-height: 30px !important;
  min-height: 30px !important;
  border-radius: 100%;
}

.icon-pound-dash,
.btn-icon-currency {
  color: var(--primary-color-text-white) !important;
}

.btn-icon-currency {
  background-color: var(
    --primary-color-background-icon-status-green
  ) !important;
  width: 30px !important;
  max-width: 30px !important;
  min-width: 30px !important;
  height: 30px !important;
  max-height: 30px !important;
  min-height: 30px !important;
  border-radius: 100%;
}

.number-text {
  font-family: "pop-Bold";
  font-size: 16px;
  color: var(--primary-color-text);
}

.info-text {
  font-family: "pop-Regular";
  font-size: 12px;
  color: var(--primary-color-text);
}

/*#region MODO RESPONSIVO */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-op-dashboard {
    padding: 0px 10px 0px 10px;
  }

  .content-filter-range-date {
    display: flex;
    justify-content: flex-end;
  }

  .content-dashboard-info {
    display: block;
    margin-bottom: 20px;
  }

  .content-dashboard-info-MXN {
    display: block;
    margin-bottom: 20px;
  }
  .content-dashboard-info-USD {
    display: block;
    margin-bottom: 20px;
  }

  .content-dashboard-oc,
  .content-dashboard-so {
    width: 100%;
    height: auto;
    margin-right: 10px;
    margin-bottom: 15px !important;
    padding: 10px 20px 10px 20px;
    background-color: var(--primary-color-background-table) !important;
    border: 1px solid var(--primary-color-border-input);
    border-radius: 10px;
    display: block;
    align-items: center;
  }

  .content-dashboard-oc-OPPending,
  .content-dashboard-so-OPPending {
    width: 100%;
    height: 80px;
    margin-right: 10px;
    margin-bottom: 15px !important;
    padding: 10px 20px 10px 20px;
    background-color: var(--primary-color-background-table) !important;
    border: 1px solid var(--primary-color-border-input);
    border-radius: 10px;
    display: block;
    align-items: center;
  }

  .content-dashboard-oc-OPPayable,
  .content-dashboard-so-OPPayable {
    width: 100%;
    height: 80px;
    margin-right: 10px;
    margin-bottom: 15px !important;
    padding: 10px 20px 10px 20px;
    background-color: var(--primary-color-background-table) !important;
    border: 1px solid var(--primary-color-border-input);
    border-radius: 10px;
    display: block;
    align-items: center;
  }

  .content-finished {
    margin-right: 0%;
    width: 100%;
    min-width: 10%;
  }

  .content-paid {
    margin-top: 5px;
    margin-left: 0%;
    width: 100%;
    min-width: 10%;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-filter-range-date {
    display: flex;
    justify-content: flex-end;
  }

  .content-dashboard-info {
    display: block;
    margin-bottom: 0px;
  }
  .content-dashboard-info-MXN {
    display: flex;
    margin-bottom: 20px;
  }
  .content-dashboard-info-USD {
    display: flex;
    margin-bottom: 0px;
  }

  .content-dashboard-oc,
  .content-dashboard-so {
    width: 100%;
    height: 100px;
    margin-right: 10px;
    margin-bottom: 15px !important;
    padding: 10px 10px 10px 10px;
    background-color: var(--primary-color-background-table) !important;
    border: 1px solid var(--primary-color-border-input);
    border-radius: 10px;
    display: block;
    align-items: center;
  }

  .content-dashboard-oc-OPPending,
  .content-dashboard-so-OPPending {
    width: 100%;
    height: 80px;
    margin-right: 10px;
    margin-bottom: 15px !important;
    padding: 10px 20px 10px 20px;
    background-color: var(--primary-color-background-table) !important;
    border: 1px solid var(--primary-color-border-input);
    border-radius: 10px;
    display: block;
    align-items: center;
  }

  .content-dashboard-oc-OPPayable,
  .content-dashboard-so-OPPayable {
    width: 100%;
    height: 80px;
    margin-right: 10px;
    margin-bottom: 15px !important;
    padding: 10px 10px 10px 10px;
    background-color: var(--primary-color-background-table) !important;
    border: 1px solid var(--primary-color-border-input);
    border-radius: 10px;
    display: block;
    align-items: center;
  }

  .content-finished {
    margin-right: 0%;
    width: 100%;
    min-width: 10%;
  }

  .content-paid {
    margin-top: 5px;
    margin-left: 0%;
    width: 100%;
    min-width: 10%;
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO */
</style>
